const ASSET_FEEDBACK_MESSAGE = `<span class="font-weight-bold">Great</span>, asset-based financing can have better interest rates. Our lending manager will present all your options over the phone.`
// customChoice: '0' 0 has to be string
export const ASSET_CHOICES = [
  { choice: '£0 - 15k',
    customChoice: '0',
    feedback: ASSET_FEEDBACK_MESSAGE
  },
  { choice: '£15 - 50k',
    customChoice: 15000,
    feedback: ASSET_FEEDBACK_MESSAGE
  },
  { choice: '£50 - 100k +',
    customChoice: 50000,
    feedback: ASSET_FEEDBACK_MESSAGE
  },
  { choice: '£100 - 500k',
    customChoice: 100000,
    feedback: ASSET_FEEDBACK_MESSAGE
  },
  { choice: '£500k +',
    customChoice: 500000,
    feedback: ASSET_FEEDBACK_MESSAGE
  }
]
export const ASSET_TOOLTIP = `<h3 class="tooltip-title">What assets are included</h3>
<p>Assets can range from cash, raw materials and stock, to office equipment, commercial properties, residential properties, and intellectual property.</p>
<p>We have a number of both secured and unsecured options, and will only offer a secured loan if that is your preference.</p>`
