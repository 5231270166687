import store from '@/store'

const debug = require('debug')('fh:router:guards')

export function hasSlug ({ params }) {
  return Boolean(params.slug)
}

export function hasValidSlug ({ params }) {
  return store.getters.getQuestions.some(({ slug }) => slug === params.slug)
}

export function hasPreviousAnswer ({ params }) {
  debug('hasPreviousAnswer')
  const questions = store.getters.getQuestions
  const answers = store.state.Questions.answers

  const toQuestionIndex = questions.findIndex(
    ({ slug }) => slug === params.slug
  )
  if (!toQuestionIndex) {
    debug('not toQuestionIndex, returning true')
    return true
  }

  const previousQuestionId = questions[toQuestionIndex - 1].questionId
  debug('previousQuestionId', previousQuestionId)

  return Boolean(answers[previousQuestionId])
}

export function isGuardsEnabled () {
  return !store.state.Dev || !store.state.Dev.disableNavigationGuards
}

export function hasTrade () {
  return Boolean(store.getters.getAnswerById('qid_business_trade'))
}

export function hasCallbackData () {
  const { businessName, email, name, telephoneNumber } = store.state.CallBack

  return Boolean(businessName && email && name && telephoneNumber)
}

export function questionGuards (to, from, next) {
  debug('questionGuards - ENTRY')
  if (!isGuardsEnabled()) {
    return next()
  }

  if (!hasSlug(to) || !hasValidSlug(to)) {
    debug('navgating to /')
    return next('/')
  }

  next()
}

export function hasAnsweredQuestions () {
  // return Object.keys(store.getters.getQuestions).length === Object.keys(store.state.Questions.answers).length
  return true
}

export function resultsGuard (to, from, next) {
  debug('resultsGuard - to', to)
  debug('resultsGuard - from', from)

  if (!isGuardsEnabled()) {
    debug('guards are disabled')
    return next()
  }

  if (!hasAnsweredQuestions()) {
    debug('resultsGuard - has not answered questions')
    return next('/questions/')
  }

  // if (!to || !to.params || !to.params.slug || (!hasSliderValue() && to.params.slug !== 'slider')) {
  if (!to || !to.params || !to.params.slug) {
    debug('resultsGuard - navigating to join-call')
    return next('/results/join-call')
  }

  next()
}

export function confirmationGuard (to, from, next) {
  if (!isGuardsEnabled()) {
    return next()
  }

  if (!hasAnsweredQuestions()) {
    return next('/questions/')
  }

  next()
}
