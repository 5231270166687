import multiLeadSourceIDs from '../../assets/data/multiLeadSourceIDs'

const getMultiLeadSourceId = (partnerName, testCurrentPath) => {
  // get leadSource if they have multiple leadSourceIds
  // localStorage set inside /home 
  // current path = e.g /asset
  const currentPath = localStorage.getItem('currentPath') || testCurrentPath
  const findPartnerObj = multiLeadSourceIDs.find(partner => partner.name === partnerName)
  // find url path inside partner urls
  const leadSourceUrlObj = findPartnerObj.urls.find(url => url[currentPath])
  // leadSourceUrlObj { 'asset': 35 }
  if (leadSourceUrlObj) return leadSourceUrlObj[currentPath]
  // leadSourceID =  35
}

export default getMultiLeadSourceId
