import { PURPOSE_MODAL, PURPOSE_CHOICES } from '@/helpers/questions/options/purposeQuestionData'
import { WHEN_CHOICES } from '@/helpers/questions/options/whenQuestionData'
import { TYPE_CHOICES } from '@/helpers/questions/options/typeQuestionData'
import { ASSET_CHOICES, ASSET_TOOLTIP } from '@/helpers/questions/options/assetQuestionData'
import { AMOUNT, WHEN, PURPOSE, BUSINESS_TYPE, BUSINESS_INPUT, SEARCH, YEARS_TRADING, TURNOVER, ASSETS } from '@/helpers/constants/question-slugs'

export default [
  {
    questionId: 'qid_amount',
    slug: AMOUNT,
    title: 'How much would you like to borrow?',
    subtitle: "We'll compare hundreds of lenders to find you the right loan",
    min: 1000,
    type: 'gbpinput',
    inlineNextButton: true
  },
  {
    questionId: 'qid_when',
    slug: WHEN,
    title: 'When are you looking to have the funds?',
    subtitle: 'Our lending managers will ensure that you receive the funding on time.',
    type: 'when',
    inlineNextButton: false,
    choices: WHEN_CHOICES,
    feedbackMultiChoiceMessage: true
  },
  {
    questionId: 'qid_purpose',
    slug: PURPOSE,
    title: 'What is the finance for?',
    subtitle: 'We have multiple finance options and can tailor it to suit your needs',
    type: 'purpose',
    inlineNextButton: false,
    choices: PURPOSE_CHOICES,
    tooltipTitle: PURPOSE_MODAL,
    tooltipMobileOnly: true,
    feedbackMultiChoiceMessage: true
  },
  {
    questionId: 'qid_type',
    slug: BUSINESS_TYPE,
    title: 'So, what is your business type?',
    subtitle: '',
    type: 'bussiness',
    inlineNextButton: true,
    choices: TYPE_CHOICES,
    titleSmCustom: true,
    centerInput: true
  },
  {
    questionId: 'qid_business-input',
    slug: BUSINESS_INPUT,
    title: 'And what is your business called?',
    subtitle: '',
    type: 'text',
    inlineNextButton: true,
    titleSmCustom: true,
    centerInput: true
  },
  {
    questionId: 'qid_search',
    slug: SEARCH,
    title: "What's your business called?",
    subtitle: 'This will help us find the right options for you',
    type: 'search',
    titleSmCustom: true
  },
  {
    questionId: 'qid_years_trading',
    slug: YEARS_TRADING,
    title: 'How many years have you been trading?',
    subtitle: '',
    type: 'numericinput',
    inlineNextButton: true,
    titleSmCustom: true,
    centerInput: true
  },
  {
    questionId: 'qid_turnover',
    slug: TURNOVER,
    title: 'How much did your business turnover last year?',
    subtitle: 'This helps us connect you to the right lender for your business size.',
    type: 'turnover',
    inlineNextButton: false,
    customTypedFeedbackMessage: true,
    titleSmCustom: true,
    centerInput: true
  },
  {
    questionId: 'qid_assets',
    slug: ASSETS,
    title: 'What is the value of your assets?',
    subtitle: 'It’s good to understand and review a comprehensive range of options that may be available to you',
    type: 'assets',
    inlineNextButton: false,
    choices: ASSET_CHOICES,
    tooltipTitle: ASSET_TOOLTIP,
    feedbackMultiChoiceMessage: true
  }
]
