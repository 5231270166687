import { AFFILIATE_TURNOVER_FRESHOLD } from '@/helpers/constants/freshold-amounts'
import { PARAM_REQUEST_AMOUNT } from '@/helpers/constants/param-names'
import formatMoney from '@/helpers/formatting/formatMoney'
import { PURPOSE_CHOICES } from '@/helpers/questions/options/purposeQuestionData'
import getUrlParams from '@/helpers/urls/getUrlParams'
import leadSourceIds from '@/partners/leadSourceIds'
import { differenceInYears } from 'date-fns'
import { saveLead } from './thinkService'

const debug = require('debug')('fh:lead-capture')
let lead = {}

function splitName (name = '') {
  const separationIndex = name.lastIndexOf(' ')
  
  const firstName = name.substring(0, separationIndex)
  const lastName = name.substring(separationIndex + 1)

  return { firstName, lastName }
}

export async function captureLead (questionId, answer, ppcRef, query, leadSourceId) {
  debug('captureLead - ENTRY', questionId, answer)
  switch (questionId) {
    case 'qid_amount': {
      /* eslint-disable camelcase */
      const { gclid, thirdpartyid } = query

      lead = {
        request_amount: answer.result,
        gclid,
        external_reference: thirdpartyid,
        lead_source: query.lead_source || leadSourceId
      }
      // usw or money gclid is set as ?ref not gclid
      if (leadSourceId === leadSourceIds.usw || leadSourceId === leadSourceIds.money) {
        const { ref } = query
        lead.gclid = ref
      }

      if (ppcRef && ppcRef.startsWith('bing')) {
        lead.bing = ppcRef
      } else {
        lead.ppc_ref = ppcRef
      }

      if (query.loan_category) {
        lead.loan_category = Number(query.loan_category)
      }

      if (query.bing) {
        lead.bing = query.bing
      }

      debug('lead', lead)
      return
    }
    case 'qid_when': {
      // If url contains param ?requestAmount
      // The LP has been skipped 
      // Request amount in url from user input on example thinkbusinessloans.com
      // Skips home page with loan input straight to url questions/when
      // As request amount will be in url to when question
      // Format the value from  ?requestAmount add to lead object
      // Add lead source id also
      const paramRequestAmount = getUrlParams(PARAM_REQUEST_AMOUNT)
      // Is requestAmount present in url?
      if (paramRequestAmount) {
        const { gclid, thirdpartyid } = query
        const amount = formatMoney(paramRequestAmount)
        lead = {
          request_amount: amount,
          gclid,
          external_reference: thirdpartyid,
          lead_source: query.lead_source || leadSourceId
        }

        localStorage.setItem('amount', amount)
        // PPC and QUERY 
        // usw or money gclid is set as ref not gclid
        if (lead.lead_source === leadSourceIds.usw) {
          const { ref } = query
          lead.gclid = ref
          // ?requestAmount means money test variant
          // money are sending the amount in the params ?requesAmount
          // Journey starts with test design at when question
          lead.external_reference = 'test'
        }
        if (lead.lead_source === leadSourceIds.money) {
          const { ref } = query
          lead.gclid = ref
          // ?requestAmount means money test variant
          // money are sending the amount in the params ?requesAmount
          // Journey starts with test design at when question
          lead.external_reference = 'Money Exclusive'
          lead.lead_source = 142
        }
        // Repeated in amount case also
        if (ppcRef && ppcRef.startsWith('bing')) {
          lead.bing = ppcRef
        } else {
          lead.ppc_ref = ppcRef
        }
  
        if (query.loan_category) {
          lead.loan_category = Number(query.loan_category)
        }
  
        if (query.bing) {
          lead.bing = query.bing
        }
        // END PPC and QUERY
      } else {
        // no requestAmount in params
        // journey started from LP
        // Control variant from Money
        if (lead.lead_source === leadSourceIds.usw) {
          lead.external_reference = 'control'
        }
        if (lead.lead_source === leadSourceIds.money) {
          lead.external_reference = 'Money Affiliate'
        }
      }
      debug('lead', lead)
      return
    }
    case 'qid_purpose': {
      const foundChoiceObject = PURPOSE_CHOICES.find(item => item.choice === answer.result)
      if (foundChoiceObject.loanCategory) {
        lead.loan_category = Number(foundChoiceObject.loanCategory)
      }
      return
    }
    case 'qid_search': {
      lead.company_name = answer.result.name
      lead.company_type = answer.result.type
      lead.company_number = answer.result.number
      lead.postcode = answer.result.address.postal_code
      lead.trading_period = differenceInYears(
        new Date(),
        answer.result.createdAt
      )
        
      debug('creating lead...')
      const leadId = await saveLead(lead)
      // checking if a number
      // as if lead has been created an object with msg is retured
      // we do not want to send this back as causes 500 error
      if (!isNaN(leadId)) {
        // local storage to keep id on refresh
        // lead is added on submitLead fnc from local storage
        localStorage.setItem('leadID', leadId)
        lead.id = leadId
      }

      debug('lead created', lead)
      return
    }
    case 'qid_business-input' : {
      lead.company_name = answer.result
      const leadId = await saveLead(lead)
      
      if (!isNaN(leadId)) {
        // local storage to keep id on refresh
        // lead is added on submitLead fnc from local storage
        localStorage.setItem('leadID', leadId)
        lead.id = leadId
      }

      return
    }
    case 'qid_years_trading': {
      lead.trading_period = answer.result
      saveLead(lead)

      return
    }
    case 'qid_turnover': {
      lead.annual_turnover = answer.result
      if (answer.result < AFFILIATE_TURNOVER_FRESHOLD) {
        lead.iwoca_affiliate = 1
      } else {
        lead.iwoca_affiliate = 0
      }

      localStorage.setItem('turnover', answer.result)
      saveLead(lead)
      return
    }
    case 'qid_assets': {
      lead.asset = answer.result
      saveLead(lead)
    }
  }
}

export function submitLead (callbackInfo) {
  debug('submitLead - ENTRY', callbackInfo)

  const { name, email, telephoneNumber } = callbackInfo
  const { firstName, lastName } = splitName(name)

  lead.contact_first_name = firstName
  lead.contact_last_name = lastName
  lead.contact_email_address = email
  lead.contact_mobile = telephoneNumber
  lead.final_step = true
  
  if (localStorage.getItem('leadID')) {
    lead.id = Number(localStorage.getItem('leadID'))
  }
  
  saveLead(lead)
}
