import _ from 'lodash'

const debug = require('debug')('fh:store:create-ppc-ref')

const bingFormat = ['engine', 'cmpid', 'keyword', 'agid', 'matchtype']
const googleFormat = ['device', 'location', 'cid', 'keyword', 'matchtype']
const ppcRefKeyNames = _.union(googleFormat, bingFormat)

function createPpcRefObject (obj, time) {
  const output = {}
  const keyNames = obj.engine === 'bing'
    ? bingFormat
    : googleFormat
      
  for (const key of keyNames) {
    output[key] = obj[key]
  }

  output.timestamp = time

  return output
}

function createPpcRefStringFromObject (obj, time) {
  const values = Object.values(obj)
  return values.join('-')
}

export default function createPpcRef (queryObject, time, cookieRepo) {
  const queryStringKeys = Object.keys(queryObject || {})

  const isAnyPpcRefKeyProvidedInQueryString = _.intersection(queryStringKeys, ppcRefKeyNames).length > 0

  if (isAnyPpcRefKeyProvidedInQueryString) {
    const ppcRef = createPpcRefObject(queryObject, time)
    cookieRepo.savePpcRef(ppcRef)
    const ppcRefStr = createPpcRefStringFromObject(ppcRef)
    debug('ppcRef obtained from querystring', ppcRefStr)
    return ppcRefStr
  } else {
    const ppcRefCookie = cookieRepo.getPpcRef() // this.$cookies.get('ppc_ref')

    if (ppcRefCookie) {
      const ppcRefStr = createPpcRefStringFromObject(ppcRefCookie, time)
      debug('ppcRef obtained from cookie', ppcRefStr)
      return ppcRefStr
    } else {
      return ''
    }
  }
}
