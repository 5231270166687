import { BR, GE } from '@/helpers/constants/param-names'
// Generic - 139
// Brand - 140
export const BIONIC_CAMPAIGN = [
  {
    paramName: GE,
    name: 'Generic',
    id: '7015J000000Z5VdQAK',
    leadSourceID: 139
  },
  {
    paramName: BR,
    name: 'Brand',
    id: '7015J000000Z5ViQAK',
    leadSourceID: 140
  }
]
