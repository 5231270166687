const debug = require('debug')('fh:mixins:SaveQuery')

function getQueryStringAsObject () {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  const output = {}
  for (var i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair.length >= 2) {
      output[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1].replace(/\+/g, '%20'))
    }
  }

  return output
}

export default {
  methods: {
    /* eslint-disable camelcase */
    mixinSaveQuery () {
      debug('this.$store.state.Think.isQuerySaved', this.$store.state.Think.isQuerySaved)
      if (this.$store.state.Think.isQuerySaved) {
        return
      }

      const queryObject = getQueryStringAsObject()

      debug('queryObject', queryObject)

      const {
        gclid,
        thirdpartyid,
        lead_source,
        loan_category,
        journey
      } = queryObject

      const query = {}

      if (gclid) {
        query['gclid'] = gclid
        this.setCookie('gclid', gclid)
      } else {
        const gclid = this.$cookies.get('gclid')
        query['gclid'] = gclid || ''
      }

      if (thirdpartyid) {
        query['thirdpartyid'] = thirdpartyid
        this.setCookie('thirdpartyid', thirdpartyid)
      } else {
        const thirdpartyid = this.$cookies.get('thirdpartyid')
        query['thirdpartyid'] = thirdpartyid || ''
      }

      if (lead_source) {
        debug('storing lead source')
        query['lead_source'] = lead_source
      }

      if (loan_category) {
        debug('setting lead source on query and cookies')
        query['loan_category'] = loan_category
      }

      if (journey) {
        this.$store.commit('SET_JOURNEY', journey)
      }

      this.$store.commit('UPDATE_QUERY', queryObject)
    },
    setCookie (key, value) {
      this.$cookies.set(key, value, '1y')
    }
  }
}
