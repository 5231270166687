import { BR, GE } from '@/helpers/constants/param-names'
import checkDoubleParam from '@/helpers/urls/checkDoubleParam'
import { BIONIC_CAMPAIGN } from '@/helpers/constants/campaign-data'

/**
 * Returns leadsource id mapped from url param ?br or ?ge if present
 *
 * @return {Number} lead source id number mapped from param ?br or ?ge
 */

export default function getBionicCampaignLeadSource () {
  // checking url for ?br or ?ge used for tracking from bionic website
  const bionicCampaignId = checkDoubleParam(BR, GE)
  if (bionicCampaignId) {
    const findCampaign = BIONIC_CAMPAIGN.find(campaign => campaign.id === bionicCampaignId)
    return findCampaign.leadSourceID
  }
}
