import { PARAM_REQUEST_AMOUNT } from '@/helpers/constants/param-names'
import { MONEY } from '@/helpers/constants/partner-names'
import findPartner from '@/helpers/partners/findPartner'
import getTestVariantPartner from '@/helpers/partners/getTestVariantPartner'
import getSubdomain from '@/helpers/urls/getSubdomain'
import getUrlParams from '@/helpers/urls/getUrlParams'

const subDomain = getSubdomain(window.location.hostname)
const paramRequestAmount = getUrlParams(PARAM_REQUEST_AMOUNT)
/**
 * Returns object with partner data 
 *
 * @return {Object} found partner object either test variant or control
 */
export default function selectedPartner () {
  if (subDomain === MONEY && paramRequestAmount) {
    return getTestVariantPartner(MONEY)
  } else {
    return findPartner(subDomain)
  }
}
