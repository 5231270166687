export const state = {
  partner: null,
  textColour: null
}
  
export const mutations = {
  SET_PARTNER (state, partner) {
    state.partner = partner
  },
  SET_TEXT_COLOUR (state, colorName) {
    state.textColour = colorName
  }
}
  
export const actions = {
  setPartner ({ commit }, partner) {
    commit('SET_PARTNER', partner)
  },
  setTextColour ({ commit }, colorName) {
    commit('SET_TEXT_COLOUR', colorName)
  }
}

export const getters = {
  getPartner: state => { 
    if (state.partner) {
      return state.partner
    } else {
      return ''
    }
  },
  isTestVariant: state => name => {
    if (!state.partner) return false
    if (state.partner.name === name && state.partner.variant_type === 'test') return true
    return false
  },
  getTextColour: state => state.textColour,
  buttonBg: (state, getters) => {
    if (getters.isTestVariant('money')) return 'money-partner__btn-2'

    if (state.partner) {
      if (state.partner.name === 'moneysupermarket') return 'moneysupermarket-partner__bg'
    }
   
    return 'bionic-blue-bg'
  },
  dynamicColor: (state, getters) => {
    if (getters.isTestVariant('money')) return 'blue-4'
    return 'white'
  },
  isMoneyTest: (state, getters) => {
    return getters.isTestVariant('money')
  },
  titleColor: (state, getters) => {
    if (getters.isMoneyTest) return 'money-partner__color-2'
    if (state.partner) {
      if (state.partner.name === 'moneysupermarket') return 'moneysupermarket-partner__color'
    }

    return ''
  }

}
