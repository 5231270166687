import { format } from 'date-fns'

const debug = require('debug')('fh:track')

const trackPageView = (eventName, fullUrl, path) => {
  const { host } = window.location
  const { title } = document
  const gtmId = process.env.VUE_APP_GTM_ID

  const item = {
    event: eventName,
    page: {
      type: 'journey',
      country: 'gb',
      environment: 'prod',
      language: 'en',
      fullURL: fullUrl,
      hostname: host,
      path,
      title,
      lastUpdated: format(new Date(), 'yyyyMMdd'),
      brand: 'think',
      author: null,
      version: '1.0',
      experimentName: '',
      variantName: '',
      firstViewed: false,
      category1: 'journey',
      productCategory: 'finance',
      product: 'finance',
      pageSection: '',
      gtm_version: '13',
      gtm_id: gtmId
    }
  }
  return item
}

const createFormSubmissionEvent = (name) => {
  return {
    event: 'formSubmission',
    form: {
      category: 'finance',
      id: 'finance-journey-form-submission',
      name,
      product: 'finance',
      productCategory: 'finance',
      status: 'submit',
      success: true,
      type: 'journey'
    }
  }
}

const track = {
  buttonClick: (data) => {
    const type = data.type || 'button'
    window.dataLayer.push({
      event: 'button',
      button: {
        type,
        class: data.class,
        id: data.id,
        name: data.name,
        text: data.text
      }
    })
  },
  buttonClickTurnover: (data) => {
    const type = data.type || 'button'
    window.dataLayer.push({
      event: 'button',
      button: {
        type,
        class: data.class,
        id: data.id,
        name: data.name,
        text: data.text
      },
      loan: {
        amount: data.amount,
        range: data.range,
        segment: data.segment
      }
    })
  },
  submitCallNow: () => {
    window.dataLayer.push(createFormSubmissionEvent('finance-form-submit'))
  },
  virtualPageView: (path) => {
    const { origin } = window.location
    const fullUrl = `${origin}${path}`

    const item = trackPageView('virtualPageView', fullUrl, path)
    window.dataLayer.push(item)
  },
  pageView: () => {
    debug('trackPageView')
    const { href, pathname } = window.location
    const item = trackPageView('pageMetaData', href, pathname)
    window.dataLayer.push(item)
  }
}

export default track
