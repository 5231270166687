const leadSourceIDs = {
  assetfinance: 137,
  bf: 100,
  bfc: 18,
  bionic: null,
  blc: 64,
  cb: 87,
  cng: 105,
  cogenthub: 81,
  comparo: 94,
  fbl: 84,
  finder: 80,
  forbes: 147,
  improveasy: 111,
  know: {
    asset: 35,
    assetLoan: 35,
    bridging: 34,
    bridgingLoan: 34,
    covid19: 1,
    invoice: 31,
    invoiceLoan: 31,
    loan: 1,
    mca: 30,
    mcaLoan: 30,
    mortgages: 33,
    mortgagesLoan: 33,
    overdrafts: 32,
    overdraftsLoan: 32,
    unsecured: 1,
    unsecuredLoan: 1,
    workingCapital: 44,
    workingCapitalLoan: 44
  },
  money: 83,
  moneyguru: 97,
  msm: 148,
  nerdwallet: {
    asset: 113,
    assetLoan: 113,
    bridging: 114,
    bridgingLoan: 114,
    covid19: 112,
    invoice: 117,
    invoiceLoan: 117,
    loan: 112,
    mca: 118,
    mcaLoan: 118,
    mortgages: 115,
    mortgagesLoan: 115,
    overdrafts: 121,
    overdraftsLoan: 121,
    unsecured: 112,
    unsecuredLoan: 112,
    workingCapital: 122,
    workingCapitalLoan: 122
  },
  nfrn: 106,
  recoveryloans: 136,
  sbp: 76,
  supacompare: 132,
  thinkwebsite: 12,
  usw: 128

}

export default leadSourceIDs
