import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import * as Campaign from '@mic/frontend/store/campaign'
import * as Window from '@mic/frontend/store/window'
import * as AbTest from '@mic/frontend/store/abTest'
import * as AddressLookup from '@mic/frontend/store/addressLookup'
import * as CallBack from '@mic/frontend/store/callBack'
import * as Dev from './dev'
import * as Questions from './questions'
import * as Covid from './covid'
import * as Think from './thinkInfoVariables'
import * as Partners from './partners'
import * as JourneyTests from './a-b-tests'

Vue.use(Vuex)

const modules = {
  Questions,
  AddressLookup,
  CallBack,
  Campaign,
  Window,
  Covid,
  Think,
  Partners,
  JourneyTests,
  AbTest
}

const plugins = []

if (process.env.NODE_ENV !== 'production') {
  modules.Dev = Dev
  plugins.push(
    new VuexPersist({
      storage: window.localStorage,
      modules: ['Dev']
    }).plugin
  )
}

export default new Vuex.Store({
  modules,
  plugins
})
