import getUrlParams from '@/helpers/urls/getUrlParams'

/**
 * Returns range object
 *
 * @param {String}  one first query param e.g br
 * @param {String}  two second query param e.g ge
 * @return {String || Null} query string result of one present param
 */
export default function checkDoubleParam (one, two) {
  return getUrlParams(one) || getUrlParams(two)
}
