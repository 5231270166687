export const state = {
  isPopupVisible: true
}

export const getters = {
  getPopupVisibility: state => state.isPopupVisible
}

export const mutations = {
  SET_VISIBILITY_ON: (state) => {
    state.isPopupVisible = true
  },

  SET_VISIBILITY_OFF: (state) => {
    state.isPopupVisible = false
  }
}
