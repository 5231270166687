import Vue from 'vue'
import bootstrap from '@mic/frontend/bootstrap'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import rg4js from 'raygun4js'

bootstrap(Vue, { router, store })

rg4js('enableCrashReporting', true)
rg4js('apiKey', process.env.VUE_APP_RAYGUN_API_KEY)

Vue.use(VueCookies)
Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
