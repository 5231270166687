import axios from 'axios'

const debug = require('debug')('fh:thinkService')

const client = axios.create({
  baseURL: process.env.VUE_APP_THINK_BASE_URI,
  headers: {
    'Content-Type': 'application/json'
  }
})

export async function saveLead (lead) {
  debug('upsertLead - ENTRY', lead)
  const response = await client.post('', lead)
  if (!lead.id) {
    return response.data.data.lead_id
  }
  
  return response.data
}
