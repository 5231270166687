// multiLeadSourceId true - getMultiLeadSourceId fnc used 
import {
  ASSETFINANCE, BC,
  BF,
  BFC,
  BIONIC,
  BLC,
  CB,
  CNG,
  COGENTHUB,
  COMPARO,
  CTM,
  FBL,
  FINDER,
  FORBES,
  IMPROVEASY,
  KNOW,
  KYM,
  MONEY,
  MONEYGURU,
  MSM,
  NERDWALLET,
  NFRN,
  RECOVERYLOANS,
  SBP,
  SUPACOMPARE,
  USW
} from '@/helpers/constants/partner-names'
import leadSourceIDs from '@/partners/leadSourceIds'
const partners = [
  {
    name: ASSETFINANCE,
    fullName: 'asset finance',
    telephoneNumber: '02038809880',
    className: 'asset-finance-partner',
    link: '',
    color: '#ff6841',
    leadSourceId: leadSourceIDs.assetfinance
  },
  {
    name: BC,
    fullName: 'business comparison',
    telephoneNumber: '02038809880',
    className: 'bc-partner',
    link: 'https://www.businesscomparison.com/',
    color: '#00afaf'
  },
  {
    name: BF,
    fullName: 'business financing',
    telephoneNumber: '02038809880',
    className: 'bf-partner',
    link: 'https://businessfinancing.co.uk/',
    color: '#31b2ed',
    leadSourceId: leadSourceIDs.bf
  },
  {
    name: BFC,
    fullName: 'business finance compared',
    telephoneNumber: '02038909880',
    className: 'bfc-partner',
    link: '/loan',
    color: '#084465',
    leadSourceId: leadSourceIDs.bfc
  },
  {
    name: BIONIC,
    fullName: 'bionic',
    telephoneNumber: '08003689705',
    className: 'bionic-partner',
    link: 'https://bionic.co.uk/',
    color: '#ff6841',
    leadSourceId: leadSourceIDs.bionic,
    logoComponent: 'SvgBionic'
  },
  {
    name: BLC,
    fullName: 'business loans compared',
    telephoneNumber: '02039233290',
    className: 'blc-partner',
    link: '/loan',
    color: '#53e953',
    leadSourceId: leadSourceIDs.blc
  },
  {
    name: CB,
    fullName: 'compare business',
    telephoneNumber: '02038809880',
    className: 'cb-partner',
    link: 'https://www.comparebusiness.co.uk/',
    color: '#25CF51',
    leadSourceId: leadSourceIDs.cb
  },
  {
    name: CNG,
    fullName: 'cng',
    telephoneNumber: '02038809880',
    className: 'cng-partner',
    link: 'https://www.cngltd.co.uk/',
    color: '#13C0D7',
    leadSourceId: leadSourceIDs.cng
  },
  {
    name: COGENTHUB,
    fullName: 'cogent hub',
    telephoneNumber: '02038809944',
    className: 'cogent-hub-partner',
    link: 'https://www.cogenthub.com/',
    color: '#126734',
    leadSourceId: leadSourceIDs.cogenthub
  },
  {
    name: COMPARO,
    fullName: 'comparo',
    telephoneNumber: '02038809880',
    className: 'comparo-partner',
    link: 'https://comparo.app/',
    color: '#d74387',
    leadSourceId: leadSourceIDs.comparo
  },
  {
    name: CTM,
    fullName: 'compare the market',
    telephoneNumber: '02038809880',
    className: 'ctm-partner',
    link: '/loan',
    color: '#118910'
  },
  {
    name: FBL,
    fullName: 'fast business loans',
    telephoneNumber: '02039233930',
    className: 'fbl-partner',
    link: 'https://www.fastbusinessloans.co.uk/',
    color: '#e57022',
    leadSourceId: leadSourceIDs.fbl
  },
  {
    name: FINDER,
    fullName: 'finder',
    telephoneNumber: '02038809880',
    className: 'finder-partner',
    link: 'https://www.finder.com/uk/',
    color: '#0271e1',
    leadSourceId: leadSourceIDs.finder
  },
  {
    name: FORBES,
    fullName: 'forbes',
    telephoneNumber: '02038809880',
    className: 'forbes-partner',
    link: 'https://www.forbes.com/uk/advisor/loans/best-small-business-loans/',
    color: '#ff6841',
    leadSourceId: leadSourceIDs.forbes,
    logoComponent: 'SvgForbes'
  },
  {
    name: IMPROVEASY,
    fullName: 'improveasy',
    telephoneNumber: '02080803200',
    className: 'improveasy-partner',
    link: '/loan',
    color: '#b03034',
    leadSourceId: leadSourceIDs.improveasy
  },
  {
    name: KNOW,
    fullName: 'know',
    telephoneNumber: '02038901140',
    className: 'know-partner',
    link: 'https://www.nerdwallet.com/uk/business-loans/',
    color: '#8dc63f',
    multiLeadSourceId: true
  },
  {
    name: KYM,
    fullName: 'know your money',
    telephoneNumber: '02038901140',
    className: 'kym-partner',
    link: 'https://www.nerdwallet.com/uk/business-loans/',
    color: '#8dc63f'
  },
  {
    name: MONEY,
    variant_type: 'control',
    fullName: 'money',
    telephoneNumber: '02038809880',
    className: 'money-partner',
    link: 'https://www.money.co.uk/business-loans',
    color: '#ff6841',
    leadSourceId: leadSourceIDs.money,
    logoComponent: 'svgMoney'
  },
  {
    name: MONEY,
    variant_type: 'test',
    fullName: 'money',
    telephoneNumber: '02038809880',
    className: 'money-partner-test',
    link: 'https://www.money.co.uk/business-loans',
    color: '#924A8B',
    leadSourceId: leadSourceIDs.money,
    logoComponent: 'svgMoney'
  },
  {
    name: MONEYGURU,
    fullName: 'money guru',
    telephoneNumber: '02038809880',
    className: 'moneyguru-partner',
    link: 'https://www.moneyguru.com/',
    color: '#983b8e',
    leadSourceId: leadSourceIDs.moneyguru
  },
  {
    name: MSM,
    fullName: 'money supermarket',
    telephoneNumber: '08000515491',
    className: 'moneysupermarket-partner',
    link: 'https://www.moneysupermarket.com/business-finance/',
    color: '#590F85',
    leadSourceId: leadSourceIDs.msm,
    logoComponent: 'SvgMSM'
  },
  {
    name: NERDWALLET,
    fullName: 'nerdwallet',
    telephoneNumber: '02038901140',
    className: 'nerdwallet-partner',
    link: 'https://www.nerdwallet.com/uk/business-loans',
    color: '#8dc63f',
    multiLeadSourceId: true
  },
  {
    name: NFRN,
    fullName: 'the federation of independant retailers',
    telephoneNumber: '02039239223',
    className: 'nfrn-partner',
    link: 'https://thefedonline.com/',
    color: '#b3282d',
    leadSourceId: leadSourceIDs.nfrn
  },
  {
    name: RECOVERYLOANS,
    fullName: 'recovery loans',
    telephoneNumber: '08008606949',
    className: 'recoveryloans-partner',
    link: '',
    color: '#ff6841',
    leadSourceId: leadSourceIDs.recoveryloans
  },
  {
    name: SBP,
    fullName: 'small business prices',
    telephoneNumber: '02038909880',
    className: 'sbp-partner',
    link: 'https://smallbusinessprices.co.uk/',
    color: '#508096',
    leadSourceId: leadSourceIDs.sbp
  },
  {
    name: SUPACOMPARE,
    fullName: 'supa compare',
    telephoneNumber: '02074014569',
    className: 'supacompare-partner',
    link: 'https://www.supacompare.com/',
    color: '#1092d3',
    leadSourceId: leadSourceIDs.supacompare
  },
  {
    name: USW,
    fullName: 'uswitch',
    telephoneNumber: '02038809880',
    className: 'usw-partner',
    link: 'https://www.uswitch.com/loans/business-loans/',
    color: '#141424',
    leadSourceId: leadSourceIDs.usw,
    logoComponent: 'SvgUswitch'
  }
]

export default partners
