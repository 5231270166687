export const ASSETFINANCE = 'assetfinance'
export const NERDWALLET = 'nerdwallet'
export const BC = 'bc'
export const BF = 'bf'
export const BFC = 'bfc'
export const BIONIC = 'bionic'
export const BLC = 'blc'
export const CB = 'cb'
export const CNG = 'cng'
export const COGENTHUB = 'cogenthub'
export const COMPARO = 'comparo'
export const COMPARE = 'compare'
export const CTM = 'ctm'
export const FBL = 'fbl'
export const FORBES = 'forbes'
export const FINDER = 'finder'
export const IMPROVEASY = 'improveasy'
export const KNOW = 'know'
export const KYM = 'kym'
export const MONEY = 'money'
export const MONEYGURU = 'moneyguru'
export const MSM = 'moneysupermarket'
export const NFRN = 'nfrn'
export const RECOVERYLOANS = 'recoveryloans'
export const SBP = 'sbp'
export const SUPACOMPARE = 'supacompare'
export const USW = 'usw'
