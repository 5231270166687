import partners from '@/partners/partners'
/**
 * Returns object with partner data 
 *
 * @param {String} partnerName for example money
 * @return {Object} found partner object
 */
export default function findPartner (partnerName) {
  return partners.find(partner => partner.name === partnerName)
}
