const multiLeadSourceUrls = (data, partnerName) => {
  // multiLeadSourceIDs = data
  const findPartnerByName = data.find(partner => partner.name === partnerName)
  const partnerUrls = findPartnerByName.urls
  let result = partnerUrls.map(inner => `/${Object.keys(inner)[0]}`)
  return result
  // result = ['/asset', 'asset-loans'] and more
  // get the urls from data create array with /
  // using in routes as alias for /home
} 

export default multiLeadSourceUrls
