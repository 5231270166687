import leadSourceIDs from '../../partners/leadSourceIds'

const multiLeadSourceIDs = [
  { name: 'know', 
    urls: [
      { 'asset': leadSourceIDs.know.asset },
      { 'asset-loan': leadSourceIDs.know.assetLoan },
      { 'bridging': leadSourceIDs.know.bridging },
      { 'bridging-loan': leadSourceIDs.know.bridgingLoan },
      { 'covid-19': leadSourceIDs.know.covid19 },
      { 'invoice': leadSourceIDs.know.invoice },
      { 'invoice-loan': leadSourceIDs.know.invoiceLoan },
      { 'loan': leadSourceIDs.know.loan },
      { 'mca': leadSourceIDs.know.mca },
      { 'mca-loan': leadSourceIDs.know.mcaLoan },
      { 'mortgages': leadSourceIDs.know.mortgages },
      { 'mortgages-loan': leadSourceIDs.know.mortgagesLoan },
      { 'overdrafts': leadSourceIDs.know.overdrafts },
      { 'overdrafts-loan': leadSourceIDs.know.overdraftsLoan },
      { 'unsecured': leadSourceIDs.know.unsecured },
      { 'unsecured-loan': leadSourceIDs.know.unsecuredLoan },
      { 'working-capital': leadSourceIDs.know.workingCapital },
      { 'working-capital-loan': leadSourceIDs.know.workingCapitalLoan }
    ]
  },
  { name: 'nerdwallet', 
    urls: [
      { 'asset': leadSourceIDs.nerdwallet.asset },
      { 'asset-loan': leadSourceIDs.nerdwallet.assetLoan },
      { 'bridging': leadSourceIDs.nerdwallet.bridging },
      { 'bridging-loan': leadSourceIDs.nerdwallet.bridgingLoan },
      { 'covid-19': leadSourceIDs.nerdwallet.covid19 },
      { 'invoice': leadSourceIDs.nerdwallet.invoice },
      { 'invoice-loan': leadSourceIDs.nerdwallet.invoiceLoan },
      { 'loan': leadSourceIDs.nerdwallet.loan },
      { 'mca': leadSourceIDs.nerdwallet.mca },
      { 'mca-loan': leadSourceIDs.nerdwallet.mcaLoan },
      { 'mortgages': leadSourceIDs.nerdwallet.mortgages },
      { 'mortgages-loan': leadSourceIDs.nerdwallet.mortgagesLoan },
      { 'overdrafts': leadSourceIDs.nerdwallet.overdrafts },
      { 'overdrafts-loan': leadSourceIDs.nerdwallet.overdraftsLoan },
      { 'unsecured': leadSourceIDs.nerdwallet.unsecured },
      { 'unsecured-loan': leadSourceIDs.nerdwallet.unsecuredLoan },
      { 'working-capital': leadSourceIDs.nerdwallet.workingCapital },
      { 'working-capital-loan': leadSourceIDs.nerdwallet.workingCapitalLoan }
    ]
  }
]

export default multiLeadSourceIDs
