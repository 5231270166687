export const WHEN_CHOICES = [
  { 
    choice: 'As soon as possible',
    tracking: {
      class: 'question-when-asap',
      id: 'question-when-asap',
      name: 'get-funds-asap'
    },
    feedback: `<span class="font-weight-bold">Thanks</span>, we'll help you gather the right documentation to speed up the decision process.`
  },
  { 
    choice: 'Within the next few weeks',
    tracking: {
      class: 'question-few-weeks',
      id: 'question-few-weeks',
      name: 'get-funds-few-weeks'
    },
    feedback: `<span class="font-weight-bold">Thanks</span>, that gives a good amount of time to fine-tune your funding options.`
  },
  { 
    choice: 'I’m just considering my options',
    tracking: {
      class: 'question-considering-options',
      id: 'question-considering-options',
      name: 'get-funds-considering-options'
    },
    feedback: `<span class="font-weight-bold">That's great</span>, starting the process earlier helps us find the perfect product for you.`
  }

]
