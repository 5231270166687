import partners from '@/partners/partners'
/**
 * Returns object with partner data for test variant
 *
 * @param {String} partnerName partner name to search
 * @return {Object} found partner object that is a test variant
 */
export default function getTestVariantPartner (partnerName) {
  return partners.find(partner => partner.name === partnerName && partner.variant_type === 'test')
}
