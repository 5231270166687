// import ThinkPayloadBuilder from '@/helpers/ThinkPayloadBuilder'
import getMultiLeadSourceId from '@/helpers/lead-source/getMultiLeadSourceId'
import questionData from '@/helpers/questions/question-sets/questionData'
import getUrlParams from '@/helpers/urls/getUrlParams'
import leadSourceIDs from '@/partners/leadSourceIds'
import { differenceInYears } from 'date-fns'
import Vue from 'vue'
import { captureLead, submitLead } from '../lead-capture'

const debug = require('debug')('fh:questions')

export const state = {
  currentQuestion: '',
  answers: {},
  showDimmer: false,
  isWorking: false,
  loanAmount: '',
  customerFirstName: null
}

export const actions = {
  actionSetAnswer: ({ commit, getters, rootState }, payload) => {
    const leadSourceId = getters.getLeadSourceId
    captureLead(payload.questionId, payload.answer, getters.getPpcRef, rootState.Think.query, leadSourceId)
    commit('SET_ANSWER', payload)
  },

  actionSubmit: async ({ commit }, payload) => {
    commit('SET_FIRST_NAME', payload.name)
    commit('SET_IS_WORKING', true)
    await submitLead(payload)
    commit('SET_IS_WORKING', false)
  }
}

const getAddressComponent = (components, componentName) => {
  const component = components.find(c => c.types.includes(componentName))

  return component && component.long_name
    ? component.long_name
    : ''
}

export const getters = {
  showDimmer: state => state.showDimmer,
  getCurrentAnswer: state => {
    return state.answers[state.currentQuestion] || {}
  },
  getAnswerById: state => id => {
    return state.answers[id]
  },
  getQuestions: (state, getters) => {
    return questionData
  },
  leadNotes: (state, getters, rootState) => {
    return ''
  },
  getLeadSourceId: (state, getters) => {
    const partner = getters.getPartner
    const leadSourceParam = getUrlParams('ls')

    if (partner && partner.multiLeadSourceId) {
      // multiLeadSourceID = true 
      // for nerdwallet & know
      const resultLeadSourceID = getMultiLeadSourceId(partner.name)
      // searches based on url and partner e.g know /asset = 35
      return resultLeadSourceID
    } else if (partner && partner.leadSourceId) {
      // leadSourceId available from partner.js
      return partner.leadSourceId
    } else if (partner && partner.name === 'bionic' && partner.leadSourceId === null) {
      // local storage set inside app.vue - used for campagin params ?ge or ?br 
      const localStorageBionicLeadSourceID = localStorage.getItem('bionicCampaignLeadSourceID')
      // used local storage id or default 93 - normal leadsource id for bionic.thinkbusinessloans.com no campaigns
      const bionicLeadSourceID = !localStorageBionicLeadSourceID ? 93 : Number(localStorageBionicLeadSourceID)
      return bionicLeadSourceID
    } else if (Number(leadSourceParam) === leadSourceIDs.thinkwebsite) {
      // if ls is set to think website (12) set
      // else do not, Think app has to have pre set lead sources
      return leadSourceIDs.thinkwebsite
    }

    return process.env.VUE_APP_LEAD_SOURCE_ID
  },
  officerName: (state, getters) => {
    const answer = getters.getAnswerById('qid_details')

    if (!answer || !answer.result || !answer.result.officer) return ''

    return answer.result.officer.name
  },
  name: (state, getters) => {
    const answer = getters.getAnswerById('qid_details')

    if (!answer || !answer.result) return ''

    return answer.result.name
  },
  trade: (state, getters) => {
    const answer = getters.getAnswerById('qid_details')

    if (!answer || !answer.result) return ''

    return answer.result.trade
  },
  officerOptions: (state, getters) => {
    const answer = getters.getAnswerById('qid_details')

    if (!answer || !answer.result) return ''

    return answer.result.officerOptions
  },
  isManual: (state, getters) => {
    const answer = getters.getAnswerById('qid_search')

    return answer && answer.result && answer.result.isManual
  },
  createdAtWords: (state, getters) => {
    const answer = getters.getAnswerById('qid_details')

    if (!answer || !answer.result) return ''

    return answer.result.createdAtWords
  },
  address: (state, getters) => {
    const answer = getters.getAnswerById('qid_details')

    if (!answer || !answer.result || !answer.result.address) return {}

    const { address } = answer.result

    debug('address getter - answer.result', address)

    const components = address.address_components
    if (components) {
      debug('there are address components', components)
      address.BuildingNumber = getAddressComponent(components, 'street_number')
      address.Street = getAddressComponent(components, 'route')
      address.City = getAddressComponent(components, 'postal_town')
      address.CountryName = getAddressComponent(components, 'country')
      address.PostalCode = getAddressComponent(components, 'postal_code')

      address.Line1 = [address.BuildingNumber, address.Street].filter(Boolean).join(', ')
      address.Line2 = [address.City, address.CountryName, address.PostalCode].filter(Boolean).join(', ')
    }

    const result = {
      Id: address.Id,
      Company: address.Company,
      Line1: address.Line1 || address.address_line_1,
      Line2: address.Line2 || address.address_line_2,
      Line3: address.Line3,
      Line4: address.Line4,
      Line5: address.Line5,
      City: address.City || address.locality,
      ProvinceName: address.ProvinceName || address.region,
      PostalCode: address.PostalCode || address.postal_code,
      CountryName: address.CountryName || 'United Kingdom',
      BuildingNumber: address.BuildingNumber,
      BuildingName: address.BuildingName,
      SubBuilding: address.SubBuilding,
      Street: address.Street,
      SecondaryStreet: address.SecondaryStreet,
      District: address.District,
      Province: address.Province
    }

    debug('address getter result', result)

    return result
  },
  yearsTrading: (state, getters) => {
    const answer = getters.getAnswerById('qid_details')

    if (!answer || !answer.result) return

    return parseInt(differenceInYears(
      new Date(),
      answer.result.createdAt
    ))
  },
  turnover: (state, getters) => {
    const answer = getters.getAnswerById('qid_turnover')

    if (!answer || !answer.result) return ''

    return answer.result
  },
  loanAmountRequested: (state, getters) => {
    const answer = getters.getAnswerById('qid_amount')

    if (!answer || !answer.result) return ''

    return answer.result
  },
  loanAmount: (state) => {
    return state.loanAmount
  },
  customerFirstName: (state) => {
    return state.customerFirstName
  }
}

export const mutations = {
  SET_SHOW_DIMMER: (state, value) => {
    state.showDimmer = value
  },
  SET_IS_WORKING: (state, value) => {
    state.isWorking = value
  },
  SET_CURRENT_QUESTION: (state, value) => {
    state.currentQuestion = value
  },
  SET_ANSWER: (state, { questionId, answer }) => {
    Vue.set(state.answers, questionId, answer)
  },
  SET_LOAN_AMOUNT: (state, loanAmount) => {
    state.loanAmount = loanAmount
  },
  SET_FIRST_NAME: (state, name) => {
    let firstName = name.split(' ')[0]
    state.customerFirstName = firstName
  }
}
