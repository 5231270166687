const PURPOSE_MESSAGE = `<span class="font-weight-bold">Great</span>. We'll discuss this in more detail with you later. Our team will customise your financing to fit your exact need.`

export const PURPOSE_CHOICES = [
  {
    choice: 'Working capital',
    feedback: PURPOSE_MESSAGE,
    tracking: {
      class: 'question-purpose-working-capital',
      id: 'question-purpose-working-capital',
      name: 'finance-purpose-working-capital'
    },
    loanCategory: 3,
    tooltip: 'Fund your day-to-day expenditures and trading operations, and assist with your business cash flow.'
  },
  {
    choice: 'Asset purchase',
    feedback: PURPOSE_MESSAGE,
    tracking: {
      class: 'question-purpose-asset-purchase',
      id: 'question-purpose-asset-purchase',
      name: 'finance-purpose-asset-purchase'
    },
    loanCategory: 7,
    tooltip: 'Use the funds to purchase or refinance an asset such as a vehicle or machinery, through a range of options including leasing or Hire purchase.'
  },
  {
    choice: 'Refinancing a loan',
    feedback: PURPOSE_MESSAGE,
    tracking: {
      class: 'question-purpose-refinancing-loan',
      id: 'question-purpose-refinancing-loan',
      name: 'finance-purpose-refinancing-loan'
    },
    tooltip: 'Exchange one loan for another. Your business can swap unaffordable debt for more affordable debt, and help your Working Capital.'
  },
  {
    choice: 'Property finance',
    feedback: PURPOSE_MESSAGE,
    tracking: {
      class: 'question-purpose-property-finance',
      id: 'question-purpose-property-finance',
      name: 'finance-purpose-property-finance'
    },
    loanCategory: 5,
    tooltip: 'Lending that is used to invest in a residential, commercial or mix-use property or development.'
  },
  {
    choice: 'Other',
    feedback: `<span class="font-weight-bold">Great</span>. We'll confirm the purpose when we discuss your business needs in more detail.`,
    tracking: {
      class: 'question-purpose-other',
      id: 'question-purpose-other',
      name: 'finance-purpose-other'
    },
    tooltip: 'Any other business reasons i.e Bill/Tax Payment, Stock purchase, Management Buy Out/Buy in, Marketing, Paying a bill, Wages, Capital Investment and Funding Growth.'
  },
  {
    choice: 'I’m not sure',
    tracking: {
      class: 'question-purpose-im-not-sure',
      id: 'question-purpose-im-not-sure',
      name: 'finance-purpose-im-not-sure'
    },
    feedback: `<span class="font-weight-bold">No problem</span>. We'll break down your business needs and help you find the perfect product.`
  }

]
const modalTitle = `<h3 class="tooltip-title">Finance products</h3>`
// remove non tooltip
const modalText = PURPOSE_CHOICES.filter(item => {
  return item.tooltip
}).map(item => {
  return `
  <p class="mb-3">
    <span class="font-weight-bold">${item.choice}:</span>
    ${item.tooltip}
  </p>`
}).join('')
// create modal text title and content from purpose_choices
export const PURPOSE_MODAL = `${modalTitle}${modalText}`
