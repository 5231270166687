import Vue from 'vue'
import createPpcRef from './create-ppc-ref'
import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

export const state = {
  tel: '08001389095',
  isQuerySaved: false,
  query: {},
  ppcRef: '',
  uuid: uuidv4()
}

export const getters = {
  getPhone: state => state.tel,
  getUuid: state => state.uuid,

  getFriendlyPhone: state => {
    return state.tel.match(/([0-9]{4})([0-9]{3})([0-9]*)/).splice(1).join(' ')
  },

  getQuery: state => state.query,

  getPpcRef: state => {
    return state.ppcRef
  }
}

const cookieRepo = {
  getPpcRef: (val) => Vue.prototype.$cookies.get('ppc_ref'),
  savePpcRef: (val) => Vue.prototype.$cookies.set('ppc_ref', val, '1y')
}

export const mutations = {
  UPDATE_QUERY (state, query) {
    state.isQuerySaved = true
    state.query = { ...state.query, ...query }

    const formattedDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
    state.ppcRef = createPpcRef(query, formattedDate, cookieRepo)
  }
}
