<template>
  <div id="app">
    <div :class="`view`">
      <transition name="fade">
        <div v-if="$store.getters.showDimmer" class="dimmer" />
      </transition>
      <router-view />
    </div>

    <modals-container />
  </div>
</template>

<script>

import Routable from '@mic/frontend/mixins/Routable'
import SaveQuery from './mixins/SaveQuery'
import { mapGetters, mapActions } from 'vuex'
import track from './helpers/track'
import selectedPartner from '@/helpers/partners/selectedPartner'
import { BIONIC } from '@/helpers/constants/partner-names'
import getBionicCampaignLeadSource from '@/helpers/campaigns/get-bionic-campaign-lead-source-id'
import './app.scss'

export default {

  mixins: [Routable, SaveQuery],

  computed: {
    ...mapGetters(['getQuery', 'getPpcRef', 'getPartner', 'getUuid']),
    pageClass () {
      return { 'rounded-page': !this.onPage(['confirmation', 'error']) }
    },
    isHome () {
      return this.onPage('home')
    },
    isProduction () {
      return process.env.NODE_ENV === 'production'
    }
  },
  mounted () {
    this.actionSetTestGroup()

    localStorage.removeItem('bionicCampaignLeadSourceID')
    const partner = selectedPartner()

    if (partner) {
      this.setPartner(partner)
      if (partner.name === BIONIC) {
        // get param from url map to campagin data return lead source id
        const leadSource = getBionicCampaignLeadSource()
        if (leadSource !== undefined) {
          localStorage.setItem('bionicCampaignLeadSourceID', leadSource)
        }
      }
    }

    this.initResize()
    track.pageView()
  },

  created () {
    this.mixinSaveQuery()
  },

  methods: {
    ...mapActions(['setPartner', 'actionSetTestGroup']),
    handleResize () {
      this.$store.commit('SET_WINDOW_SIZE', { width: window.innerWidth, height: window.innerHeight })
    },
    initResize () {
      this.handleResize()

      let resizeTimer

      window.addEventListener('resize', () => {
        clearTimeout(resizeTimer)

        resizeTimer = setTimeout(() => {
          this.handleResize()
        }, 250)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dimmer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  transition: 0.5s all;
  background-color: rgba($bionic-blue ,0.8);
  pointer-events: auto;
}

.view {
  z-index: 1;
}
</style>
