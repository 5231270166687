import normalQuestions from '@/helpers/questions/question-sets/normal-questions'
// import getUrlParams from '@/helpers/urls/getUrlParams'
// import { homePageAlias } from '@/helpers/lead-source/home-page-alias'
// import { JOURNEY_PARAM_NAME } from '@/helpers/constants/journey-names'
// import getSubdomain from '@/helpers/urls/getSubdomain'

export default function () {
  // let journey
  // // if homepage
  // // set localstorage of journey
  // // refreshing on q page will not update the journey - will stay as hp e.g ?journey=1
  // // only on hp
  // if (homePageAlias.indexOf(window.location.pathname) >= 0 || window.location.pathname === '/') {
  // // check of home page 
  //   const journeyParam = getUrlParams(JOURNEY_PARAM_NAME)
  //   localStorage.setItem(JOURNEY_PARAM_NAME, journeyParam)
  // }
  // // outside of hp use storage journey on refresh inside question pages
  // if (localStorage.getItem(JOURNEY_PARAM_NAME)) {
  //   journey = localStorage.getItem(JOURNEY_PARAM_NAME)
  // }

  // let questions
  // // const subdomain = getSubdomain(window.location.hostname)
  // // param tests e.g ?journey=feedback change questions here
  // // if (journey === COMPANY_CDC && subdomain === COMPARE) {
  // //   questions = cdcCompanySearch
  // // } else {
  // //   // normal question set 
  // //   questions = normalQuestions
  // // }

  // // logic would go here if different journey questions being tested
  // if (journey) {
  //   questions = normalQuestions
  // } else {
  //   questions = normalQuestions
  // }

  return normalQuestions
}
