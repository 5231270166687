import * as Dev from '@mic/frontend/store/dev'

export function DEFAULT_STATE () {
  return {
    ...Dev.DEFAULT_STATE(),
    enableHarmony: false
  }
}

export const state = DEFAULT_STATE()

export const mutations = {
  ...Dev.mutations,

  setEnableHarmony (state, value) {
    state.enableHarmony = value
  },

  resetState (state) {
    Object.assign(state, DEFAULT_STATE())
  }
}
