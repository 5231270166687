import Vue from 'vue'
import Router from 'vue-router'
import revision from '@/revision.json'
import { questionGuards, resultsGuard, confirmationGuard } from './guards'
import track from '../helpers/track'
import { homePageAlias } from '@/helpers/lead-source/home-page-alias'

const debug = require('debug')('fh:router')

const ThankYouEmail = () => import('@/views/ThankYouEmail.vue')
const QuestionPage = () => import('@/views/QuestionPage.vue')
const ResultsPage = () => import('@/views/ResultsPage.vue')
const CallbackConfirmation = () => import('@/views/CallbackConfirmation')
const AffiliatePage = () => import('@/views/AffiliatePage')
const Home = () => import('@/views/Home')
const ErrorView = () => import('@/views/Error')
const VersionPage = () => import('@mic/frontend/views/VersionPage')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      alias: homePageAlias,
      component: Home
    },
    {
      path: '/thank-you-email',
      name: 'thank-you-email',
      component: ThankYouEmail
    },
    {
      path: '/question/:slug?',
      name: 'questionSlug',
      component: QuestionPage,
      beforeEnter: questionGuards
    },
    {
      path: '/results/:slug?',
      name: 'results',
      component: ResultsPage,
      beforeEnter: resultsGuard
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: CallbackConfirmation,
      beforeEnter: confirmationGuard
    },
    {
      path: '/asset-confirmation',
      name: 'asset-confirmation',
      component: CallbackConfirmation,
      beforeEnter: confirmationGuard
    },
    {
      path: '/results-confirmation',
      name: 'results-confirmation',
      component: AffiliatePage
    },
    {
      path: '/lt-confirmation',
      name: 'lt-confirmation',
      component: AffiliatePage
    },
    {
      path: '/bl-confirmation',
      name: 'bl-confirmation',
      component: CallbackConfirmation
    },
    {
      path: '/wt-confirmation',
      name: 'wt-confirmation',
      component: CallbackConfirmation
    },
    {
      path: '/mj-confirmation',
      name: 'mj-confirmation',
      component: CallbackConfirmation
    },
    {
      path: '/lt-join-call',
      name: 'lt-join-call',
      component: ResultsPage
    },
    {
      path: '/bl-join-call',
      name: 'bl-join-call',
      component: ResultsPage
    },
    {
      path: '/wt-join-call',
      name: 'wt-join-call',
      component: ResultsPage
    },
    {
      path: '/mj-join-call',
      name: 'mj-join-call',
      component: ResultsPage
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorView
    },
    {
      path: '/version',
      name: 'version',
      component: VersionPage,
      props: {
        revision
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.afterEach((to) => {
  debug('to', to)

  if (to.path === '/') {
    return
  }

  track.virtualPageView(to.path)
})

export default router
