export const state = {
  journeyType: null
}
  
export const mutations = {
  SET_JOURNEY (state, journey) {
    state.journeyType = String(journey)
  }
}
  
export const actions = {
  setJourney ({ commit }, journey) {
    commit('SET_JOURNEY', journey)
  }
}

export const getters = {
  getJourney: state => state.journeyType
}
