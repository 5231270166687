// polyfill for URLSearchParams
import 'url-search-params-polyfill'
// gets the paramName e.g
// cmt.local/loan?journey=2
// returns 2 as a string
// returns null if no match
export default function (paramName) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(paramName)
}
