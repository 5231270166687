export const LIMITED_COMPANY = 'Limited company'
export const SOLE_TRADER = 'Sole trader or Partnership'
export const CHARITY = 'Charity or other'

export const TYPE_CHOICES = [
  { 
    choice: LIMITED_COMPANY,
    tracking: {
      class: 'business-type-limited-company',
      id: 'business-type-limited-company',
      name: 'business-type'
    }
  },
  { 
    choice: SOLE_TRADER,
    tracking: {
      class: 'business-type-sole-trader-partnership',
      id: 'business-type-sole-trader-partnership',
      name: 'business-type'
    }
  },
  { 
    choice: CHARITY,
    tracking: {
      class: 'business-type-charity-other',
      id: 'business-type-charity-other',
      name: 'business-type'
    }
  }

]
